<template>
    <landing :breadCrumbs="breadCrumbs" :shopName="shopName">
        <v-container>
            <v-row
                id="description"
                justify="center"
                :class="smAndUp ? 'mb-15' : null"
            >
                <v-col cols="auto" align="center">
                    <heading><span class="red--text">妙</span>典塩焼店</heading>
                </v-col>
            </v-row>

            <!-- 紹介文 -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">アクセス</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >妙典駅徒歩5分!</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">住所</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >千葉県市川市塩焼2丁目1-9</span
                        >
                    </div>
                </v-col>
                <v-col cols="12" md="6">
                    <div :class="smAndUp ? 'text-h4' : 'text-body-1'">
                        <span class="red--text">営業時間</span>:<span
                            :class="smAndUp ? 'text-h6' : 'text-body-2'"
                            >平日13:00～22:00/土日10:00～18:00<br />※時間外は応相談</span
                        >
                    </div>
                    <div :class="smAndUp ? 'text-h6' : 'text-body-2'">
                        <span class="red--text">定休日</span>:<span
                            :class="smAndUp ? 'text-body-1' : 'text-caption'"
                            >なし</span
                        >
                    </div>
                </v-col>
            </v-row>

            <!-- 代表イメージ -->
            <v-row justify="end">
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img height="360px" :src="imageMv"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <v-img
                            position="bottom center"
                            height="360px"
                            :src="shopImage"
                        ></v-img>
                    </v-card>
                </v-col>
                <v-col v-if="!smAndUp" cols="12" align="center">
                    <v-icon
                        small
                        color="red darken-2"
                        @click="$vuetify.goTo('#target')"
                        >fas fa-chevron-down</v-icon
                    >
                    駅からのご案内
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <GmapMap
                            :center="center"
                            :zoom="zoom"
                            map-type-id="terrain"
                            style="width: 100%; height: 360px"
                        >
                            <GmapMarker
                                :key="index"
                                v-for="(m, index) in markers"
                                :position="m.position"
                                :clickable="true"
                                :draggable="true"
                                @click="center = m.position"
                            />
                        </GmapMap>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card outlined style="border-style: none">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!4v1616998347132!6m8!1m7!1ssXOxxcIyJNLMYQ-2ihKzLw!2m2!1d35.68849149319528!2d139.9272704309671!3f189.22790146732325!4f-13.806295471244766!5f0.7820865974627469"
                            width="100%"
                            height="360px"
                            style="border: 0"
                            allowfullscreen=""
                            loading="lazy"
                        ></iframe>
                    </v-card>
                </v-col>
            </v-row>

            <!-- 店舗個別メッセージ -->
            <shop-message
                :message="this.message"
                shopImage="static/noimage.jpg"
            />

            <!-- ナビゲーション -->
            <v-row justify="center" :class="smAndUp ? 'mb-15' : null">
                <v-col cols="auto" align="center">
                    <heading
                        ><span id="target" class="red--text">妙典駅</span
                        >からお越しの方</heading
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">1</span>.
                            右側の改札を出ます。
                        </div></v-card-title
                    >
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessA"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">2</span>.
                            セブンイレブン側に向かっていきます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessB"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">3</span>.
                            公園の交差点を左に曲がります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessC"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">4</span>.
                            マツモトキヨシやドコモショップの通りを真っ直ぐ進みます。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessD"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">5</span>.
                            駅から2つ目のセブンイレブンの交差点で漢方屋側へ渡ります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessE"></v-img>
                    </v-card>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card-title class="pl-0"
                        ><div class="navigation">
                            <span class="red--text text-md-h6">6</span>.
                            コインランドリー、焼肉屋、カフェのある建物の通りを真っ直ぐ進み、美容室の隣の建物がREVOIST塩焼店になります。
                        </div>
                    </v-card-title>
                    <v-card outlined style="border-style: none">
                        <v-img height="auto" :src="accessF"></v-img>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>

        <footer-contents></footer-contents>
    </landing>
</template>

<script>
import Heading from '@/components/Heading.vue'
import FooterContents from '@/components/FooterContents.vue'
import ShopMessage from '@/components/landing/ShopMessage.vue'
import Landing from '@/views/Landing.vue'

export default {
    mounted() {
        document.title = `${this.$route.meta.title} | パーソナルトレーニングジム【REVOISTレボイスト】`
        document.querySelector('meta[name="description"]').setAttribute(
            'content',
            `パーソナルジム REVOISTの5号店です！
妙典駅南口から南側に向かって徒歩4分の建物もオシャレなジムです！
自宅から近くのため、運動着で来館して、そのままパーソナルトレーニングを受けることもできます！
現在、妙典にお住まいの多くのお客様に通っていただいております！
身体の知識が豊富なパ―ソナルトレーナーが、身体の動きや姿勢を確認しながら、あなたの身体を改善するために、オーダーメイドのレッスンをします！
`
        )
    },
    components: {
        Heading,
        FooterContents,
        ShopMessage,
        Landing,
    },
    data() {
        return {
            shopName: '妙典塩焼店',

            center: {
                lat: 35.68845765982409,
                lng: 139.92711295620538,
            },
            zoom: 18,
            markers: [
                {
                    position: {
                        lat: 35.68845765982409,
                        lng: 139.92711295620538,
                    },
                    title: '妙典塩焼店',
                },
            ],
            message: `パーソナルジム REVOISTの5号店です！<br />
妙典駅南口から南側に向かって徒歩4分の建物もオシャレなジムです！<br />
自宅から近くのため、運動着で来館して、そのままパーソナルトレーニングを受けることもできます！<br />
現在、妙典にお住まいの多くのお客様に通っていただいております！<br />
身体の知識が豊富なパ―ソナルトレーナーが、身体の動きや姿勢を確認しながら、あなたの身体を改善するために、オーダーメイドのレッスンをします！<br /><br />
レッスンでは「楽しく、わかりやすく」をモットーに皆様に寄り添ってサポートさせていただきます！理想の体に向けて一緒に頑張っていきましょう！<br />
妙典塩焼店トレーナー：

`,
        }
    },
    computed: {
        smAndUp() {
            return this.$vuetify.breakpoint.smAndUp
        },
        breadCrumbs() {
            return [
                {
                    text: 'トップ',
                    disabled: false,
                    href: './',
                },
                {
                    text: '店舗一覧・アクセス',
                    disabled: false,
                    href: 'gyms',
                },
                {
                    text: '妙典塩焼店',
                    disabled: true,
                    href: 'access-5',
                },
            ]
        },

        imageMv() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_5/shioyaki7@2x.png'
                : 'static/sp_access_5/shioyaki7@2x.png'
        },
        accessA() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_5/shioyaki1.png'
                : 'static/sp_access_5/shioyaki1.png'
        },
        accessB() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_5/shioyaki2.png'
                : 'static/sp_access_5/shioyaki2.png'
        },
        accessC() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_5/shioyaki3.png'
                : 'static/sp_access_5/shioyaki3.png'
        },
        accessD() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_5/shioyaki4.png'
                : 'static/sp_access_5/shioyaki4.png'
        },
        accessE() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_5/shioyaki5.png'
                : 'static/sp_access_5/shioyaki5.png'
        },
        accessF() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/pc_access_5/shioyaki6.png'
                : 'static/sp_access_5/shioyaki6.png'
        },
        accessG() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'public/static/pc_access_5/shioyaki7.png'
                : 'public/static/sp_access_5/shioyaki7.png'
        },
        shopImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/MyodenShioyaki.jpg'
                : 'static/landing/MyodenShioyaki.jpg'
        },
        trainerImage() {
            return this.$vuetify.breakpoint.smAndUp
                ? 'static/landing/trainer/妙典塩焼店　石井雄大トレーナー.jpg'
                : 'static/landing/trainer/妙典塩焼店　石井雄大トレーナー.jpg'
        },
    },
}
</script>
<style lang="scss">
.navigation {
    min-height: 3em;
}
</style>
